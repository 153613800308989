(function ($) {
    $(function () {


        $('.slider-product').flexslider({
            animation: "slide",
            controlNav: "thumbnails"
        });

        $('.slider-page').flexslider({
            animation: "fade",
            controlNav: false,
            directionNav: true,
            smoothHeight: true
        });


        $('.slider-news').flexslider({
            animation: "fade",
            controlNav: true,
            directionNav: false,
            slideshowSpeed: 3000,
            animationSpeed: 1000,
        });


        $('.header-background').flexslider({
            controlNav: false,
            directionNav: false,
        });


        $('#collapse-user').on('shown.bs.collapse', function () {
            $('html, body').animate({
                scrollTop: $(this).offset().top
            })
        });


        $('[data-select-lang]').on('click', function () {
            var lang = $(this).data('select-lang');
            $.cookie('lang', lang, {
                expires: 7,
                path: '/'
            });
        });

        //$('[data-search-form]').collapse();


        $('[data-c-search]').on('click', function (event) {
            event.preventDefault();
            $('[data-search-box]').collapse('hide');
            $('[data-search-form]').collapse('toggle');

        });

        $('[data-search-form]').on('shown.bs.collapse', function() {
            console.log('shown');
            //$('[data-search-box]').collapse('hide');
        });
        $('[data-search-form]').on('show.bs.collapse', function () {
            console.log('show');


        });

    });
})(jQuery);